<template>
  <div class="row employeeFullAclWrap">
    <div class="col-12 col-lg-4">
      <h2>Menüpunkte</h2>
      <div class="form-group" v-for="(menu, name) in e.rights.menu">
        <div class="btn-group me-3">
          <input type="radio" value="no" class="btn-check" :id="name+'-menu-read-none'" autocomplete="off"
                 v-model="e.rights.menu[name]">
          <label class="btn btn-outline-danger btn-sm" :for="name+'-menu-read-none'">{{ $t("globals.no") }}</label>
          <input type="radio" value="yes" class="btn-check" :id="name+'-menu-read-yes'" autocomplete="off"
                 v-model="e.rights.menu[name]">
          <label class="btn btn-outline-primary btn-sm" :for="name+'-menu-read-yes'">{{ $t("globals.yes") }}</label>
        </div>
        <label>{{ $t('nav.' + name) }}</label>
      </div>
    </div>
    <div class="col-12 col-lg-4">
      <h2>Zugriffsrechte</h2>
      <div class="form-group">
        <div class="btn-group me-3">
          <input type="radio" value="no" class="btn-check" id="addOwners-read-none" autocomplete="off"
                 v-model="e.rights.addOwners">
          <label class="btn btn-outline-danger btn-sm" for="addOwners-read-none">{{ $t("globals.no") }}</label>
          <input type="radio" value="yes" class="btn-check" id="addOwners-read-yes" autocomplete="off"
                 v-model="e.rights.addOwners">
          <label class="btn btn-outline-primary btn-sm" for="addOwners-read-yes">{{ $t("globals.yes") }}</label>
        </div>
        <label>Darf Mitarbeiter Zugriffsrechte konfigurieren</label>
      </div>
      <div class="form-group">
        <h6 class="mt-4">{{ $t("globals.access") }}</h6>
        <div class="btn-group me-3" v-if="e.rights && e.rights.products">
          <input type="radio" value="none" class="btn-check" id="products-read-none" autocomplete="off"
                 v-model="e.rights.products.read">
          <label class="btn btn-outline-danger btn-sm" for="products-read-none">{{ $t("globals.no") }}</label>
          <input type="radio" value="yes" class="btn-check" id="products-read-yes" autocomplete="off"
                 v-model="e.rights.products.read">
          <label class="btn btn-outline-primary btn-sm" for="products-read-yes">{{ $t("globals.yes") }}</label>
        </div>
        <label>{{ $t("globals.products") }}</label>
      </div>
      <div class="form-group">
        <div class="btn-group me-3">
          <input type="radio" value="none" class="btn-check" id="config-read-none" autocomplete="off"
                 v-model="e.rights.config.read">
          <label class="btn btn-outline-danger btn-sm" for="config-read-none">{{ $t("globals.no") }}</label>
          <input type="radio" value="yes" class="btn-check" id="config-read-yes" autocomplete="off"
                 v-model="e.rights.config.read">
          <label class="btn btn-outline-primary btn-sm" for="config-read-yes">{{ $t("globals.yes") }}</label>
        </div>
        <label>{{ $t("globals.settings") }}</label>
      </div>
      <div class="form-group">
        <div class="btn-group me-3">
          <input type="radio" value="none" class="btn-check" id="times-read-none" autocomplete="off"
                 v-model="e.rights.times.read">
          <label class="btn btn-outline-danger btn-sm" for="times-read-none">{{ $t("globals.no") }}</label>
          <input type="radio" value="yes" class="btn-check" id="times-read-yes" autocomplete="off"
                 v-model="e.rights.times.read">
          <label class="btn btn-outline-primary btn-sm" for="times-read-yes">{{ $t("globals.yes") }}</label>
        </div>
        <label>{{ $t("globals.workflow") }}</label>
      </div>
      <div class="form-group">
        <div class="btn-group me-3">
          <input type="radio" value="none" class="btn-check" id="activities-read-none" autocomplete="off"
                 v-model="e.rights.activities.read">
          <label class="btn btn-outline-danger btn-sm" for="activities-read-none">{{ $t("globals.no") }}</label>
          <input type="radio" value="yes" class="btn-check" id="activities-read-yes" autocomplete="off"
                 v-model="e.rights.activities.read">
          <label class="btn btn-outline-primary btn-sm" for="activities-read-yes">{{ $t("globals.yes") }}</label>
        </div>
        <label>{{ $t("globals.activities") }}</label>
      </div>
      <div class="form-group">
        <div class="btn-group me-3">
          <input type="radio" value="none" class="btn-check" id="dashboard-read-none" autocomplete="off"
                 v-model="e.rights.dashboard.read">
          <label class="btn btn-outline-danger btn-sm" for="dashboard-read-none">{{ $t("globals.no") }}</label>
          <input type="radio" value="yes" class="btn-check" id="dashboard-read-yes" autocomplete="off"
                 v-model="e.rights.dashboard.read">
          <label class="btn btn-outline-primary btn-sm" for="dashboard-read-yes">{{ $t("globals.yes") }}</label>
        </div>
        <label>{{ $t("globals.dashboard") }}</label>
      </div>
      <div class="form-group">
        <h6 class="mt-4">{{ $t("globals.employee") }}</h6>
        <div class="btn-group me-3">
          <input type="radio" value="none" class="btn-check" id="employee-read-none" autocomplete="off"
                 v-model="e.rights.employees.read">
          <label class="btn btn-outline-danger btn-sm" for="employee-read-none">{{ $t("globals.none") }}</label>
          <input type="radio" value="own" class="btn-check" id="employee-read-own" autocomplete="off"
                 v-model="e.rights.employees.read">
          <label class="btn btn-outline-secondary btn-sm" for="employee-read-own">{{ $t("globals.own") }}</label>
          <input type="radio" value="all" class="btn-check" id="employee-read-all" autocomplete="off"
                 v-model="e.rights.employees.read">
          <label class="btn btn-outline-primary btn-sm" for="employee-read-all">{{ $t("globals.all") }}</label>
        </div>
        <label>{{ $t("acl.dataSetsRead") }}</label>
      </div>
      <div class="form-group">
        <div class="btn-group me-3">
          <input type="radio" value="none" class="btn-check" id="employee-create-none" autocomplete="off"
                 v-model="e.rights.employees.create">
          <label class="btn btn-outline-danger btn-sm" for="employee-create-none">{{ $t("globals.no") }}</label>
          <input type="radio" value="all" class="btn-check" id="employee-create-all" autocomplete="off"
                 v-model="e.rights.employees.create">
          <label class="btn btn-outline-primary btn-sm" for="employee-create-all">{{ $t("globals.yes") }}</label>
        </div>
        <label>{{ $t("acl.dataSetsCreate") }}</label>
      </div>
      <div class="form-group">
        <div class="btn-group me-3">
          <input type="radio" value="none" class="btn-check" id="employee-update-none" autocomplete="off"
                 v-model="e.rights.employees.update">
          <label class="btn btn-outline-danger btn-sm" for="employee-update-none">{{ $t("globals.no") }}</label>
          <input type="radio" value="all" class="btn-check" id="employee-update-all" autocomplete="off"
                 v-model="e.rights.employees.update">
          <label class="btn btn-outline-primary btn-sm" for="employee-update-all">{{ $t("globals.yes") }}</label>
        </div>
        <label>{{ $t("acl.dataSetsRefresh") }}</label>
      </div>
      <div class="form-group">
        <div class="btn-group me-3">
          <input type="radio" value="none" class="btn-check" id="employee-delete-none" autocomplete="off"
                 v-model="e.rights.employees.delete">
          <label class="btn btn-outline-danger btn-sm" for="employee-delete-none">{{ $t("globals.no") }}</label>
          <input type="radio" value="all" class="btn-check" id="employee-delete-all" autocomplete="off"
                 v-model="e.rights.employees.delete">
          <label class="btn btn-outline-primary btn-sm" for="employee-delete-all">{{ $t("globals.yes") }}</label>
        </div>
        <label>{{ $t("acl.dataSetsDelete") }}</label>
      </div>
      <div class="form-group">
        <h6 class="mt-4">{{ $t("acl.customerDataSets") }}</h6>
        <div class="btn-group me-3">
          <input type="radio" value="none" class="btn-check" id="customers-read-none" autocomplete="off"
                 v-model="e.rights.customers.read">
          <label class="btn btn-outline-danger btn-sm" for="customers-read-none">{{ $t("globals.none") }}</label>
          <input type="radio" value="own" class="btn-check" id="customers-read-own" autocomplete="off"
                 v-model="e.rights.customers.read">
          <label class="btn btn-outline-secondary btn-sm" for="customers-read-own">{{ $t("globals.own") }}</label>
          <input type="radio" value="all" class="btn-check" id="customers-read-all" autocomplete="off"
                 v-model="e.rights.customers.read">
          <label class="btn btn-outline-primary btn-sm" for="customers-read-all">{{ $t("globals.all") }}</label>
        </div>
        <label>{{ $t("acl.dataSetsRead") }}</label>
      </div>
      <div class="form-group">
        <div class="btn-group me-3">
          <input type="radio" value="none" class="btn-check" id="customers-create-none" autocomplete="off"
                 v-model="e.rights.customers.create">
          <label class="btn btn-outline-danger btn-sm" for="customers-create-none">{{ $t("globals.none") }}</label>
          <input type="radio" value="own" class="btn-check" id="customers-create-own" autocomplete="off"
                 v-model="e.rights.customers.create">
          <label class="btn btn-outline-secondary btn-sm" for="customers-create-own">{{ $t("globals.own") }}</label>
          <input type="radio" value="all" class="btn-check" id="customers-create-all" autocomplete="off"
                 v-model="e.rights.customers.create">
          <label class="btn btn-outline-primary btn-sm" for="customers-create-all">{{ $t("globals.all") }}</label>
        </div>
        <label>{{ $t("acl.dataSetsCreate") }}</label>
      </div>
      <div class="form-group">
        <div class="btn-group me-3">
          <input type="radio" value="none" class="btn-check" id="customers-update-none" autocomplete="off"
                 v-model="e.rights.customers.update">
          <label class="btn btn-outline-danger btn-sm" for="customers-update-none">{{ $t("globals.none") }}</label>
          <input type="radio" value="own" class="btn-check" id="customers-update-own" autocomplete="off"
                 v-model="e.rights.customers.update">
          <label class="btn btn-outline-secondary btn-sm" for="customers-update-own">{{ $t("globals.own") }}</label>
          <input type="radio" value="all" class="btn-check" id="customers-update-all" autocomplete="off"
                 v-model="e.rights.customers.update">
          <label class="btn btn-outline-primary btn-sm" for="customers-update-all">{{ $t("globals.all") }}</label>
        </div>
        <label>{{ $t("acl.dataSetsRefresh") }}</label>
      </div>
      <div class="form-group">
        <div class="btn-group me-3">
          <input type="radio" value="none" class="btn-check" id="customers-delete-none" autocomplete="off"
                 v-model="e.rights.customers.delete">
          <label class="btn btn-outline-danger btn-sm" for="customers-delete-none">{{ $t("globals.none") }}</label>
          <input type="radio" value="own" class="btn-check" id="customers-delete-own" autocomplete="off"
                 v-model="e.rights.customers.delete">
          <label class="btn btn-outline-secondary btn-sm" for="customers-delete-own">{{ $t("globals.own") }}</label>
          <input type="radio" value="all" class="btn-check" id="customers-delete-all" autocomplete="off"
                 v-model="e.rights.customers.delete">
          <label class="btn btn-outline-primary btn-sm" for="customers-delete-all">{{ $t("globals.all") }}</label>
        </div>
        <label>{{ $t("acl.dataSetsDelete") }}</label>
      </div>
    </div>
    <div class="col-12 col-lg-4">
      <div class="form-group">
        <h6 class="mt-4">{{ $t("globals.orders") }}</h6>
        <div class="btn-group me-3">
          <input type="radio" value="none" class="btn-check" id="documents-read-none" autocomplete="off"
                 v-model="e.rights.documents.read">
          <label class="btn btn-outline-danger btn-sm" for="documents-read-none">{{ $t("globals.none") }}</label>
          <input type="radio" value="own" class="btn-check" id="documents-read-own" autocomplete="off"
                 v-model="e.rights.documents.read">
          <label class="btn btn-outline-secondary btn-sm" for="documents-read-own">{{ $t("globals.own") }}</label>
          <input type="radio" value="all" class="btn-check" id="documents-read-all" autocomplete="off"
                 v-model="e.rights.documents.read">
          <label class="btn btn-outline-primary btn-sm" for="documents-read-all">{{ $t("globals.all") }}</label>
        </div>
        <label>{{ $t("acl.dataSetsRead") }}</label>
      </div>
      <div class="form-group">
        <div class="btn-group me-3">
          <input type="radio" value="none" class="btn-check" id="documents-create-none" autocomplete="off"
                 v-model="e.rights.documents.create">
          <label class="btn btn-outline-danger btn-sm" for="documents-create-none">{{ $t("globals.none") }}</label>
          <input type="radio" value="own" class="btn-check" id="documents-create-own" autocomplete="off"
                 v-model="e.rights.documents.create">
          <label class="btn btn-outline-secondary btn-sm" for="documents-create-own">{{ $t("globals.own") }}</label>
          <input type="radio" value="all" class="btn-check" id="documents-create-all" autocomplete="off"
                 v-model="e.rights.documents.create">
          <label class="btn btn-outline-primary btn-sm" for="documents-create-all">{{ $t("globals.all") }}</label>
        </div>
        <label>{{ $t("acl.dataSetsCreate") }}</label>
      </div>
      <div class="form-group">
        <div class="btn-group me-3">
          <input type="radio" value="none" class="btn-check" id="documents-update-none" autocomplete="off"
                 v-model="e.rights.documents.update">
          <label class="btn btn-outline-danger btn-sm" for="documents-update-none">{{ $t("globals.none") }}</label>
          <input type="radio" value="own" class="btn-check" id="documents-update-own" autocomplete="off"
                 v-model="e.rights.documents.update">
          <label class="btn btn-outline-secondary btn-sm" for="documents-update-own">{{ $t("globals.own") }}</label>
          <input type="radio" value="all" class="btn-check" id="documents-update-all" autocomplete="off"
                 v-model="e.rights.documents.update">
          <label class="btn btn-outline-primary btn-sm" for="documents-update-all">{{ $t("globals.all") }}</label>
        </div>
        <label>{{ $t("acl.dataSetsRefresh") }}</label>
      </div>
      <div class="form-group">
        <div class="btn-group me-3">
          <input type="radio" value="none" class="btn-check" id="documents-delete-none" autocomplete="off"
                 v-model="e.rights.documents.delete">
          <label class="btn btn-outline-danger btn-sm" for="documents-delete-none">{{ $t("globals.none") }}</label>
          <input type="radio" value="own" class="btn-check" id="documents-delete-own" autocomplete="off"
                 v-model="e.rights.documents.delete">
          <label class="btn btn-outline-secondary btn-sm" for="documents-delete-own">{{ $t("globals.own") }}</label>
          <input type="radio" value="all" class="btn-check" id="documents-delete-all" autocomplete="off"
                 v-model="e.rights.documents.delete">
          <label class="btn btn-outline-primary btn-sm" for="documents-delete-all">{{ $t("globals.all") }}</label>
        </div>
        <label>{{ $t("acl.dataSetsDelete") }}</label>
      </div>
      <div class="form-group">
        <h6 class="mt-4">{{ $t("acl.salesRelatedness") }}</h6>
        <div class="btn-group me-3">
          <input type="radio" value="no" class="btn-check" id="documentsSales-delete-none" autocomplete="off"
                 v-model="e.rights.documents.umsatz">
          <label class="btn btn-outline-danger btn-sm" for="documentsSales-delete-none">{{ $t("globals.no") }}</label>
          <input type="radio" value="yes" class="btn-check" id="documentsSales-delete-yes" autocomplete="off"
                 v-model="e.rights.documents.umsatz">
          <label class="btn btn-outline-primary btn-sm" for="documentsSales-delete-yes">{{ $t("globals.yes") }}</label>
        </div>
        <label>{{ $t("acl.dataSetsDelete") }}</label>
      </div>
      <div class="form-group">
        <div class="btn-group me-3">
          <input type="radio" value="no" class="btn-check" id="documentsSales-read-none" autocomplete="off"
                 v-model="e.rights.documents.umsatzs">
          <label class="btn btn-outline-danger btn-sm" for="documentsSales-read-none">{{ $t("globals.no") }}</label>
          <input type="radio" value="yes" class="btn-check" id="documentsSales-read-yes" autocomplete="off"
                 v-model="e.rights.documents.umsatzs">
          <label class="btn btn-outline-primary btn-sm" for="documentsSales-read-yes">{{ $t("globals.yes") }}</label>
        </div>
        <label>{{ $t("acl.salesAccessRead") }}</label>
      </div>
      <div class="form-group">
        <h6 class="mt-4">{{ $t("acl.statisticAccess") }}</h6>
        <div class="btn-group me-3">
          <input type="radio" value="no" class="btn-check" id="statistic-read-none" autocomplete="off"
                 v-model="e.rights.statistic">
          <label class="btn btn-outline-danger btn-sm" for="statistic-read-none">{{ $t("globals.no") }}</label>
          <input type="radio" value="yes" class="btn-check" id="statistic-read-yes" autocomplete="off"
                 v-model="e.rights.statistic">
          <label class="btn btn-outline-primary btn-sm" for="statistic-read-yes">{{ $t("globals.yes") }}</label>
        </div>
        <label>{{ $t("acl.employeeStatisticAccess") }}</label>
      </div>
      <div class="form-group">
        <h6 class="mt-4">{{ $t("acl.accountingAccess") }}</h6>
        <div class="btn-group me-3">
          <input type="radio" value="no" class="btn-check" id="documentBox-read-none" autocomplete="off"
                 v-model="e.rights.documentbox">
          <label class="btn btn-outline-danger btn-sm" for="documentBox-read-none">{{ $t("globals.no") }}</label>
          <input type="radio" value="yes" class="btn-check" id="documentBox-read-yes" autocomplete="off"
                 v-model="e.rights.documentbox">
          <label class="btn btn-outline-primary btn-sm" for="documentBox-read-yes">{{ $t("globals.yes") }}</label>
        </div>
        <label class="mt-2"><small>{{ $t("acl.employeeAccountingAccess") }}</small></label>
      </div>
      <div class="form-group">
        <h6 class="mt-4">Ticket-System</h6>
        <div class="btn-group me-3">
          <input type="radio" value="no" class="btn-check" id="issues-read-none" autocomplete="off"
                 v-model="e.rights.issues">
          <label class="btn btn-outline-danger btn-sm" for="issues-read-none">{{ $t("globals.no") }}</label>
          <input type="radio" value="yes" class="btn-check" id="issues-read-yes" autocomplete="off"
                 v-model="e.rights.issues">
          <label class="btn btn-outline-primary btn-sm" for="issues-read-yes">{{ $t("globals.yes") }}</label>
        </div>
        <label class="mt-2"><small>Zugang zum Ticket-System</small></label>
      </div>
    </div>
  </div>
  <hr>
</template>
<script>
import DocumentItem from "@/views/components/document/DocumentItem.vue";

export default {
  name: "Acl",
  components: {DocumentItem},
  props: {
    employee: {},
  },
  emits: ["acl-changed"],
  data: function () {
    return {
      e: {
        rights: {
          menu: {
            dashboard: 'no',
            customers: 'no',
            documents: 'no',
            products: 'no',
            activities: 'no',
            projects: 'no',
            queue: 'no',
            reports: 'no',
            config_basic: 'no',
            employees: 'no',
            times: 'no',
            config_employees: 'no',
            issues: 'no',
            drafts: 'no',
            events: 'no',
            config_workflow: 'no',
            files: 'no',
            sepa: 'no',
            legal: 'no',
            accounting: 'no',
            config_accounting: 'no',
          },
          issues: 'no',
          addOwners: 'no',
          products: {
            read: "none"
          },
          config: {
            read: "none"
          },
          times: {
            read: "none"
          },
          activities: {
            read: "none"
          },
          dashboard: {
            read: "none"
          },
          employees: {
            read: "none",
            create: "none",
            update: "none",
            delete: "none"
          },
          customers: {
            read: "all",
            create: "none",
            update: "none",
            delete: "none"
          },
          accessJoomla: {
            read: "none"
          },
          documents: {
            read: "none",
            create: "none",
            update: "none",
            delete: "none",
            umsatz: 'no',
            umsatzs: 'no'
          },
          statistic: 'no',
          documentbox: 'no',
        }
      },
      initComplete: false
    }
  },
  created() {
    // next tick
    this.$nextTick(() => {
      if (this.employee && this.employee.rights && this.employee.rights.config) {
        this.e.rights = this.employee.rights;
        if (!this.e.rights.menu) {
          this.e.rights.menu = {
            dashboard: 'yes',
            customers: 'yes',
            documents: 'yes',
            products: 'yes',
            activities: 'yes',
            queue: 'yes',
            reports: 'yes',
            config_basic: 'yes',
            employees: 'yes',
            times: 'yes',
            config_employees: 'yes',
            issues: 'yes',
            drafts: 'yes',
            events: 'yes',
            config_workflow: 'yes',
            files: 'yes',
            sepa: 'yes',
            legal: 'yes',
            accounting: 'yes',
            config_accounting: 'yes',
            projects: 'yes',
          }
        }
      }
      this.initComplete = true;
    });
  },
  methods: {
    manipulateRights() {
      this.$emit('acl-changed', this.e.rights);
    },
  },
  watch: {
    "e.rights": {
      deep: true,
      handler() {
        if (this.initComplete) {
          this.manipulateRights();
        }
      },
    },
    employee: {
      immediate: true, // Run immediately when the component is created
      deep: true, // Ensure deep watching of the object
      handler(newEmployee) {
        if (newEmployee && newEmployee.rights) {
          this.e.rights = newEmployee.rights;

          if (!this.e.rights.menu) {
            this.e.rights.menu = {
              dashboard: 'yes',
              customers: 'yes',
              documents: 'yes',
              products: 'yes',
              activities: 'yes',
              queue: 'yes',
              reports: 'yes',
              config_basic: 'yes',
              employees: 'yes',
              times: 'yes',
              config_employees: 'yes',
              issues: 'yes',
              drafts: 'yes',
              events: 'yes',
              config_workflow: 'yes',
              files: 'yes',
              sepa: 'yes',
              legal: 'yes',
              accounting: 'yes',
              config_accounting: 'yes',
              projects: 'yes',
            };
          }
        }
        this.initComplete = true;
      },
    },
  },
}
</script>

